import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};

type PostLoginBody = {
  email: string;
  password: string;
};
type queryUser={
  page:number,
  search:string
}
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    PostAddNotification: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.notification,
        method: "POST",
        body,
      }),
    }),

   

    GetAllIndexNotifications: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; bookingType?: any }>({
      query: ({ page, search, bookingType }) => {
        // let url = `${END_POINTS.notification}?query="listing"`;
        let url = `${END_POINTS.notification}?pushType=listing`;

        const queryParams = [];
    
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (search) {
          queryParams.push(`search=${search}`);
        }
        if (bookingType) {
          queryParams.push(`bookingType=${bookingType}`);
        }
    
        if (queryParams.length > 0) {
          url += `&${queryParams.join('&')}`;
        }
        
        return {
          url: url,
          method: "GET",
        };
      },
    }),
   
    
   
    getSpecificBookingById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.booking}/${userId}/`,
        method: "GET"
      })
    }),
  
    bookingCsv: builder.query<CommonResponseType & { data: any }, {}>
    ({
      query: ({  }) => ({
        url: `${END_POINTS.export}`,
        method: "GET"
      })
    }),
   
  
    EditUser: builder.mutation<
    CommonResponseType & { data: CommonBody },
    { id: string | undefined; body: CommonBody }
  >({
    query: ({ body, id }) => ({
      url: `${END_POINTS.updateParent}/${id}`,
      method: "PUT",
      body,
    }),
  }),
  
  getReviewerNotifications: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; bookingType?: any }>({
    query: ({ page, search, bookingType }) => {
      // let url = `${END_POINTS.notification}?query="listing"`;
      let url = `${END_POINTS.notification}?pushType=icon`;

      const queryParams = [];
  
      if (page) {
        queryParams.push(`page=${page}`);
      }
      if (search) {
        queryParams.push(`search=${search}`);
      }
      if (bookingType) {
        queryParams.push(`bookingType=${bookingType}`);
      }
  
      if (queryParams.length > 0) {
        url += `&${queryParams.join('&')}`;
      }
      
      return {
        url: url,
        method: "GET",
      };
    },
  }),
      
    deleteNotification:  builder.query<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.notification}/${userId}/`,
          method: "DELETE",
        }),
      }),
 
  }),
});

export const {
    
  useLazyGetSpecificBookingByIdQuery,
    useEditUserMutation,
    useLazyDeleteNotificationQuery,
    useLazyBookingCsvQuery,
    useLazyGetReviewerNotificationsQuery,
    usePostAddNotificationMutation,
    useLazyGetAllIndexNotificationsQuery
} = authApi;
