import emptySplitApi from "../utils/rtk";
import { User, updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
   s3Location?:string
};

type PostLoginBody = {
  email: string;
  password: string;
};
type queryUser={
  page:number,
  search:string
}
export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
  
    getAllCustomer: builder.query<CommonResponseType & { data: any }, { value?:number,page?: number; search?: string }>({
      query: ({ value,page, search }) => {
        let url = END_POINTS.customersupport;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (search) {
          queryParams.push(`search=${search}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if(value!=undefined)
        queryParams.push(`supportType=${value+1}`)
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    
    
   
    
 
   
    PostRevertQuery: builder.mutation<
    CommonResponseType & { data: CommonBody },
    { id: any | undefined; body: CommonBody }
  >({
    query: ({ body, id }) => ({
      url: `${END_POINTS.revertQuery}/${id}`,
      method: "POST",
      body,
    }),
  }),
  
    EditUser: builder.mutation<
    CommonResponseType & { data: CommonBody },
    { id: string | undefined; body: CommonBody }
  >({
    query: ({ body, id }) => ({
      url: `${END_POINTS.updateParent}/${id}`,
      method: "PUT",
      body,
    }),
  }),


 
  
    
      
    deleteCustomerSupportById:  builder.query<CommonResponseType & { data: any }, { userId: any}>({
        query: ({ userId }) => ({
          url: `${END_POINTS.deleteCustomerSupport}/${userId}`,
          method: "DELETE",
        }),
      }),
 
  }),
});

export const {
  usePostRevertQueryMutation,
  useLazyGetAllCustomerQuery,
  useLazyDeleteCustomerSupportByIdQuery
    
} = authApi;
