import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetAllUserQuery, useLazyGetSpecificUserByIdQuery } from "../../services/users";
import { errorToast, successToast } from "../../helpers";
// import { UserResponse } from "../../types/General";

// type props = {
//   userData: UserResponse | undefined;
// };
import { useLazyDeleteByIduserQuery } from "../../services/users";
import Loader from "../../helpers/Loader";
const Details = () => {
  const navigate = useNavigate();
  const[ByIdDetails,setByIdDetails]=useState<any>([]);
  console.log("uegeed",ByIdDetails);
 const[fetchApi,{isLoading}]=useLazyGetSpecificUserByIdQuery();
//  const[isLoading,setisLoading]=useState(false);
  const { _id } = useParams();
  const [getallusers]=useLazyGetAllUserQuery();
 const[deleteUserById]=useLazyDeleteByIduserQuery();
  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
 
  const[rows,setRows]=useState<any>([]);
  console.log("rows",rows);
  

  // const { data: tutorData, isLoading, isError } = useLazyGetutorByIdQuery(_id);

  const fetchAcceptedTutor = async () => {
    try {
      const response = await getallusers({}).unwrap();
      console.log("909111", response);
      if (response.statusCode === 200) {
        setRows(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAcceptedTutor();
  }, []);
  const handleDeleteUser = async (userId: any) => {
    try {
      const response = await deleteUserById({ userId }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Tutor deleted successfully");

        fetchAcceptedTutor();
      }
    } catch (error: any) {
      console.error(error);
      errorToast(error?.message || "");
    }
  };
  useEffect(() => {

    fetchAcceptedTutor();
  }, []);

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);
  return (
    <Card className="cards">
         <Loader isLoad={isLoading} />
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img src={ByIdDetails?.image?ByIdDetails?.image:"/static/images/user_placeholder.png" }alt="" />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Full Name</Typography>
                  <Typography component="p">{ByIdDetails?.name?ByIdDetails?.name:"-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Country Code </Typography>
                  <Typography component="p">{ByIdDetails?.dialCode}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p">{ByIdDetails?.phoneNo?ByIdDetails?.phoneNo:"-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p"> {ByIdDetails?.email?ByIdDetails?.email:"-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Account Status</Typography>
                  <Typography component="p"> {ByIdDetails?.isBlocked===false?"Active":"Blocked"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Address</Typography>
                  <Typography component="p">{ByIdDetails?.address?ByIdDetails?.address:"-"}</Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Booking Details</Typography>
                  <Typography component="p">La, New York</Typography>
                </Box>
              </Grid> */}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                {/* <Box>
                  <Typography component="h5">Payment Details</Typography>
                  <Typography component="p">--</Typography>
                </Box> */}
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography component="h5">Bio</Typography>
                  <Typography component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Details;
function useGetUserByIdQuery(): [any] {
  throw new Error("Function not implemented.");
}

