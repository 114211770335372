import emptySplitApi from "../utils/rtk";
import { updateProfile } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

 

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    
  
    putUpdateProfile: builder.mutation<
      CommonResponseType & { data: updateProfile },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "PUT",
        body,
      }),
    }),
  
  }),
});

export const {
 
    usePutUpdateProfileMutation
 
} = authApi;
