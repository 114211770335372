export const STORAGE_KEYS = {
  token: "TOKEN",
  tempToken: "TEMP_TOKEN",
  credentials: "CREDENTIALS",
  location: "LOCATION",
  userData: "USER_DATA",
  fcmToken: "FCM_TOKEN",
  USER_ROLE: "USER_ROLE",
  WalletAddress: "WALLET_ADDRESS",
  tutorId:"TUTOR_ID",
};
