import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  useLazyGetRefundCompletedListQuery,
  useLazyGetRefundListQuery,
  usePostRefundBookingAmountMutation,
} from "../../services/booking";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/Loader";
import { isValidInput } from "../../utils/validation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { errorToast, successToast } from "../../helpers/toast";
import { CommonBody } from "../../types/General";
import RefundModal from "../../components/modals/RefundModal";
import RefundReasonModal from "../../components/modals/RefundReasonModal";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ManageRefundRequest = () => {
  const navigate = useNavigate();
  const [fetchApi] = useLazyGetRefundListQuery();
  const [fetchComplete] = useLazyGetRefundCompletedListQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [rows, setRows] = useState<any[]>([]);
  const [completedRows, setCompletedRows] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [completedTotalCount, setCompletedTotalCount] = useState<number>(0);
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState<string>("");
  const totalPages = Math.ceil(totalCount / 10);
  const completedTotalPages = Math.ceil(completedTotalCount / 10);
  const [selectedId, setSelectedId] = useState<any>();
  const { _id } = useParams();
  const [reason, setReason] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [postRefundBookingAmount, { isLoading: isRefundLoading }] =
    usePostRefundBookingAmountMutation();
  const fetchData = async (page: number, search: string) => {
    setIsLoading(true);
    try {
      const response = await fetchApi({ page, search }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.booking || []);
        setTotalCount(response?.data?.totalBooking);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataCompleteRefund = async (page: number, search: string) => {
    setIsLoading(true);
    try {
      const response = await fetchComplete({ page, search }).unwrap();
      if (response.statusCode === 200) {
        setCompletedRows(response?.data?.booking || []);
        setCompletedTotalCount(response?.data?.totalBooking);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleAcceptRefund = async (id: string) => {
    setIsLoading(true);
    try {
      let body = {};
      if (status === "Approve") {
        body = {
          refundStatus: 1,
        };
      } else {
        body = {
          refundStatus: 2,
          rejectReason: reason,
        };
      }

      console.log("bookingStatus", body);
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await postRefundBookingAmount({
        id,
        encryptedBody,
      }).unwrap();
      if (response.statusCode === 200) {
        successToast(response?.message || "Refund accepted successfully");
        fetchData(page, debouncedSearchTerm);
      }
      setIsLoading(false);
    } catch (error: any) {
      console.error(error);
      setIsLoading(false);
      errorToast(
        error?.data?.message || "An error occurred while accepting the refund"
      );
    }
  };
  useEffect(() => {
    if (value === 0) {
      fetchData(page, debouncedSearchTerm);
    } else {
      fetchDataCompleteRefund(page, debouncedSearchTerm);
    }
  }, [page, debouncedSearchTerm, value]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    if (value === 0) {
      setRows([]);
    } else {
      setCompletedRows([]);
    }
  };

  function truncateString(str: string) {
    let words = str.trim().split(/\s+/);
    if (words.length <= 3) {
      return str;
    }
    return words.slice(0, 3).join(" ") + " ...";
  }

  const formatDateTime = (datetime: string) => {
    const date = new Date(datetime);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
    if (newValue === 0) {
      fetchData(1, debouncedSearchTerm);
      setCompletedRows([]);
    } else {
      fetchDataCompleteRefund(1, debouncedSearchTerm);
      setRows([]);
    }
  };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_layout">
        <div className="dashboard">
          <h2 className="mn_hdng">Manage Refund</h2>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Refunded Transactions" {...a11yProps(0)} />
              <Tab label="Refund Requests" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Parent Details</TableCell>
                      <TableCell>Phone No</TableCell>
                      <TableCell>Refund Amount</TableCell>
                      <TableCell>Refund Date & Time</TableCell>
                      <TableCell>Refund Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Refunded Transaction found.
                        </TableCell>
                      </TableRow>
                    ) : (
                      rows.map((row, index) => (
                        <TableRow key={row._id}>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell>
                            <div>{row?.parent?.name}</div>
                            <div>{row?.parent?.email || "-"}</div>
                          </TableCell>
                          <TableCell>
                            {row?.parent?.dialCode + row?.parent?.phoneNo ||
                              "-"}
                          </TableCell>
                          <TableCell>{row?.refundAmount || "-"}</TableCell>
                          <TableCell>
                            {formatDateTime(row?.refundDate) || "-"}
                          </TableCell>
                          <TableCell
                            style={{
                              color: row?.isRefunded ? "#65a442" : "red",
                              fontWeight: "bolder",
                            }}
                          >
                            {row?.isRefunded === true
                              ? "Success"
                              : "Failed" || "-"}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {rows.length > 0 && (
                <Pagination
                  setPage={setPage}
                  module={rows}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                />
              )}
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Parent Details</TableCell>
                      <TableCell>Phone No</TableCell>
                      <TableCell>Refund Amount</TableCell>
                      <TableCell>Refund Date & Time</TableCell>
                      <TableCell>Reason for Refund</TableCell>
                      <TableCell align="center">Refund Request</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {completedRows.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No refund request found.
                        </TableCell>
                      </TableRow>
                    ) : (
                      completedRows.map((row, index) => (
                        <TableRow key={row._id}>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell>
                            <div>{row?.parent?.name}</div>
                            <div>{row?.parent?.email || "-"}</div>
                          </TableCell>
                          <TableCell>
                            {row?.parent?.dialCode + row?.parent?.phoneNo ||
                              "-"}
                          </TableCell>
                          <TableCell>{row?.refundAmount || "-"}</TableCell>
                          <TableCell>
                            {formatDateTime(row?.refundDate) || "-"}
                          </TableCell>
                          <TableCell>
                            {row?.cancelReason
                              ? truncateString(row?.cancelReason)
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#008000",
                                color: "#ffffff",
                                marginRight: "5px",
                              }}
                              onClick={() => {
                                setStatus("Approve");
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                              // disabled={row?.tutorWithdrawals[0]?.withdrawStatus === "approved"}
                            >
                              Accept
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "red",
                                color: "#ffffff",
                              }}
                              onClick={() => {
                                setStatus("Reject");
                                setOpen1(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              Reject
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(`/refundRequestDetails/${row._id}`)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {completedRows.length > 0 && (
                <Pagination
                  setPage={setPage}
                  module={completedRows}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={completedTotalPages}
                />
              )}
            </CustomTabPanel>
          </Box>
        </Card>
        <RefundReasonModal
          reason={reason}
          setReason={setReason}
          open={open1}
          setOpen={setOpen1}
          handleDelete={() => handleAcceptRefund(selectedId)}
        />
        <RefundModal
          open={open}
          setOpen={setOpen}
          handleDelete={() => handleAcceptRefund(selectedId)}
          name="refund"
          status={status}
        />
      </div>
    </MainContainer>
  );
};

export default ManageRefundRequest;
