import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLazyGetTutorEarningByIdQuery, useUpdateWithdrawStatusMutation } from "../../services/tutor";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

const Earning = () => {
  const { _id } = useParams<{ _id: string }>();  
  const [rows, setRows] = useState<any[]>([]);
  const [totalEarning, setTotalEarning] = useState<number>(0);
  const [netBalance, setNetBalance] = useState<number>(0);
  const [withdrawalRequest, setWithdrawalRequest] = useState<number>(0);
  const [fetchApi, { isLoading }] = useLazyGetTutorEarningByIdQuery();
  const [updateWithdrawStatus, { isLoading: isUpdating }] = useUpdateWithdrawStatusMutation();

  const fetchEarningsById = async () => {
    try {
      const response = await fetchApi({ userId: _id }).unwrap();
      if (response?.statusCode === 200) {
        setRows(response?.data?.booking?.data || []);
        setTotalEarning(parseFloat(response?.data?.totalEarning?.earn.toFixed(2)) || 0);
        setWithdrawalRequest(parseFloat(response?.data?.withdrawAmount.toFixed(2)) || 0);
        setNetBalance(parseFloat(response?.data?.balance.toFixed(2)) || 0);
      }
    } catch (e: any) {
      // errorToast(e?.data?.message ||"");
    }
  };

  const handleWithdrawStatus = async (status: number) => {
    if (withdrawalRequest === 0) {
      errorToast("You will not be able to withdraw due to 0 amount");
      return;
    }

    try {
      let body = {
        withdrawStatus: status
      };
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await updateWithdrawStatus({ userId: _id, encryptedBody }).unwrap();
      if (response?.statusCode === 200) {
        successToast(response?.data?.message || "Withdraw status updated successfully");
        fetchEarningsById(); 
      }
    } catch (e: any) {
      errorToast(e?.message);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchEarningsById();
    }
  }, [_id]);

  if (!_id) {
    return null;  
  }

  return (
    <Card className="cards">
      <h2 className="mn_hdng" style={{ marginTop: 0 }}>
        Earnings
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography className="custom_label">Total Earning: ${totalEarning}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className="custom_label">Net Balance: ${netBalance}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className="custom_label">Withdrawal Request: ${withdrawalRequest}</Typography>
        </Grid>
        {/* <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '10px' }}>
          <Button variant="contained" color="primary" onClick={() => handleWithdrawStatus(2)} disabled={isUpdating}>
            Accept
          </Button>
          <Button variant="contained" color="secondary" onClick={() => handleWithdrawStatus(3)} disabled={isUpdating}>
            Reject
          </Button>
        </Grid> */}
      </Grid>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell>Booking Id</TableCell>
              <TableCell>Parent Name</TableCell>
              <TableCell>Total Cost</TableCell>
              {/* <TableCell>Status</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography style={{ marginLeft: 400 }}>No earning</Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => (
                <TableRow key={row?._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>#{row?.bookingNumber || "-"}</TableCell>
                  <TableCell>{row?.parents?.name || "-"}</TableCell>
                  <TableCell>${row?.grandTotal?.toFixed(2) || "-"}</TableCell>
                  {/* <TableCell>Received</TableCell> */}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Earning;
