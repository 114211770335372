import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { UploadMedia, Uploadpdf } from "../../utils/uploadMedia";
import { errorToast, successToast } from "../../helpers";
import {
  useLazyDeleteDocsByIdQuery,
  usePostaddDocumentsMutation,
} from "../../services/documentation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useUpdateIdProofMutation,
  useLazyGetIdProofByIdQuery,
} from "../../services/documentation";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
const Idproof = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [profile_picture1, setProfilePicture1] = useState<any>(null);
  const [profile_picture2, setProfilePicture2] = useState<any>(null);
  const [profile_picture3, setProfilePicture3] = useState<any>(null);
  const [profile_picture4, setProfilePicture4] = useState<any>(null);
  const [profile_picture5, setProfilePicture5] = useState<any>(null);
  const [profile_picture3_front, setProfilePicture3Front] = useState<any>(null);
  const [profile_picture3_back, setProfilePicture3Back] = useState<any>(null);
  const [error3_front, setError3Front] = useState<string>("");
  const [error3_back, setError3Back] = useState<string>("");

  const [error1, setError1] = useState<string>("");
  const [error2, setError2] = useState<string>("");
  const [error3, setError3] = useState<string>("");
  const [error4, setError4] = useState<string>("");
  const [error5, setError5] = useState<string>("");
  const [showDocument1, setShowDocument1] = useState<boolean>(false);
  const [showDocument2, setShowDocument2] = useState<boolean>(false);
  const [showDocument3, setShowDocument3] = useState<boolean>(false);
  const [showDocument4, setShowDocument4] = useState<boolean>(false);
  const [showDocument5, setShowDocument5] = useState<boolean>(false);
  const [deleteDocById] = useLazyDeleteDocsByIdQuery();

  const [getIdProofById] = useLazyGetIdProofByIdQuery();
  const [IdDetails, setIdDetails] = useState<any>([]);
  console.log("eugduy3dxe", IdDetails);
  const [updateId] = useUpdateIdProofMutation();

  const [tutorId, setTutorId] = useState<any>();
  const [fetchApi] = usePostaddDocumentsMutation();

  // Function to handle image upload
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        return;
      }

      const res =
        file?.type === "application/pdf"
          ? await Uploadpdf(file)
          : await UploadMedia(file);
      if (res?.statusCode === 200) {
        switch (type) {
          case "icon-button-file-vdo1":
            setProfilePicture1(res?.data?.image);
            break;
          case "icon-button-file-vdo3-front":
            setProfilePicture3Front(res?.data?.image);
            break;
          case "icon-button-file-vdo3-back":
            setProfilePicture3Back(res?.data?.image);
            break;
          case "icon-button-file-vdo2":
            setProfilePicture2(res?.data?.image);
            break;
          case "icon-button-file-vdo3":
            setProfilePicture3(res?.data?.image);
            break;
          case "icon-button-file-vdo4-front":
            setProfilePicture4(res?.data?.image);
            break;
          case "icon-button-file-vdo4-back":
            setProfilePicture5(res?.data?.image);
            break;
          default:
            break;
        }
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
  };

  const handleDeleteClick = async (_id: string, documentName: number) => {
    try {
      const response = await deleteDocById({ userId: _id }).unwrap();
      if (response.statusCode === 200) {
        const updatedItems = IdDetails.filter((item: any) => item._id !== _id);
        setIdDetails(updatedItems);

        switch (documentName) {
          case 1:
            setShowDocument1(false);
            setProfilePicture1(null);
            break;
          case 2:
            setShowDocument2(false);
            setProfilePicture2(null);
            break;
          case 3:
            setShowDocument3(false);
            setProfilePicture3Front(null);
            setProfilePicture3Back(null);
            break;
          case 4:
            setShowDocument4(false);
            setProfilePicture4(null);
            break;
          case 5:
            setShowDocument5(false);
            setProfilePicture5(null);
            break;
          default:
            break;
        }

        successToast("Document deleted successfully");
      } else {
        errorToast("Failed to delete document. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting document:", error);
      errorToast("Failed to delete document. Please try again.");
    }
  };

  const fetchDataById = async (tutorId: string, documentType?: number) => {
    try {
      const response = await getIdProofById({
        userId: tutorId,
        documentType: 4,
      }).unwrap();
      if (response.statusCode === 200) {
        setIdDetails(response?.data);

        response.data.forEach((item: any) => {
          switch (item.documentName) {
            case 1:
              setShowDocument1(true);
              setProfilePicture1(item.frontImage);
              break;
            case 2:
              setShowDocument2(true);
              setProfilePicture2(item.frontImage);
              break;
            case 3:
              setShowDocument3(true);
              setProfilePicture3Front(item.frontImage);
              setProfilePicture3Back(item.backImage);
              break;
            case 4:
              setShowDocument4(true);
              setProfilePicture4(item.frontImage);
              break;
            case 5:
              setShowDocument5(true);
              setProfilePicture5(item.frontImage);
              break;
            default:
              break;
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
    }
  }, [_id]);
  // Function to handle checkbox change
  const handleCheckboxChange = (documentType: number) => {
    switch (documentType) {
      case 1:
        setShowDocument1(!showDocument1);
        break;
      case 2:
        setShowDocument2(!showDocument2);
        break;
      case 3:
        setShowDocument3(!showDocument3);
        break;
      case 4:
        setShowDocument4(!showDocument4);
        break;
      case 5:
        setShowDocument5(!showDocument5);
        break;
      default:
        break;
    }
  };

  const apiCall = async (payload: any) => {
    console.log("formDataArray:", payload);

    const documents = payload?.documents?.map((formData: any) => ({
      frontImage: formData.frontImage,
      documentType: formData.documentType,
      description: "Description here",
      documentName: formData.documentName,
    }));

    const body1 = {
      tutorId: payload?.tutorId,
      documents: payload?.documents,
    };
    const body2 = {
      documents: payload?.documents,
    };

    console.log(body1, "222222");

    if (IdDetails?._id) {
      try {
        const bodyWithId = {
          ...body1,
        };

        let encryptedBody = (await generateEncryptedKeyBody(
          bodyWithId
        )) as CommonBody;
        let response = await fetchApi(encryptedBody).unwrap();

        if (response?.statusCode === 200)
          successToast("Id-Proof Updated successfully");
        console.log("Response:", response);
      } catch (error: any) {
        errorToast(error?.message);
      }
    } else {
      try {
        let encryptedBody = (await generateEncryptedKeyBody(
          body1
        )) as CommonBody;
        let response = await fetchApi(encryptedBody).unwrap();
        if (response?.statusCode === 200)
          successToast("Id-Proof Added successfully");
        console.log("Response:", response);
      } catch (error: any) {
        errorToast(error?.message);
      }
    }
  };

  const handleAddClick = () => {
    const selectedTutorId = _id ? _id : tutorId;
    const formDataArray: any[] = [];

    const documentConfigurations = [
      {
        show: showDocument1,
        profile_picture: profile_picture1,
        documentName: 1,
      },
      {
        show: showDocument2,
        profile_picture: profile_picture2,
        documentName: 2,
      },
      {
        show: showDocument3,
        profile_picture: profile_picture3_front,
        profile_picture_back: profile_picture3_back,
        documentName: 3,
      },
      {
        show: showDocument4,
        profile_picture: profile_picture4,
        documentName: 4,
      },
      {
        show: showDocument5,
        profile_picture: profile_picture5,
        documentName: 5,
      },
    ];

    for (const config of documentConfigurations) {
      if (config.show) {
        const tutorIdToPush = IdDetails.find(
          (item: any) => item.documentName === config.documentName
        )?.tutorId;
        const docIdPush = IdDetails.find(
          (item: any) => item.documentName === config.documentName
        )?._id;
        formDataArray.push({
          frontImage: config.profile_picture || "",
          backImage: config.profile_picture_back || "",
          description: "",
          documentName: config.documentName,
          documentType: 4,
          ...(tutorIdToPush && { tutorId: tutorIdToPush, _id: docIdPush }),
        });
      }
    }

    console.log("formDataArray:", formDataArray);

    if (formDataArray.length > 0) {
      const payload = {
        tutorId: selectedTutorId,
        documents: formDataArray,
      };
      apiCall(payload);
    } else {
      errorToast("Please select at least one Id Proof");
    }
  };

  useEffect(() => {
    const storedTutorId = localStorage.getItem("tutorId");
    if (storedTutorId) {
      setTutorId(storedTutorId);
    }
  }, []);

  return (
    <div className="main_layout">
      <div className="dashboard">
        <h1 className="mn_hdng">Id Proof</h1>
        <Button
          className="btn btn_primary"
          onClick={() => navigate("/manage-tutors")}
        >
          Back
        </Button>
      </div>
      <Card className="cards">
        <div className="dashboard"></div>
        <CardContent>
          <div className="card_main">
            <Grid container spacing={2}>
              {/* Document 1 */}
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={showDocument1}
                  onChange={() => handleCheckboxChange(1)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="body1" style={{ marginLeft: "8px" }}>
                  Government Attested Education Documents
                </Typography>
                {/* Add image and PDF upload functionality */}
                {showDocument1 && !profile_picture1 && (
                  <Box
                    className="upload_image_bnr"
                    style={{ marginLeft: "16px" }}
                  >
                    <label htmlFor="icon-button-file-vdo1">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file-vdo1"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg,application/pdf", // Allow images and PDFs
                        }}
                        onChange={(event: any) =>
                          handleImageUpload(event, "icon-button-file-vdo1")
                        }
                      />
                      <Button component="span" className="upload_image_btn">
                        <AddIcon />
                      </Button>
                    </label>
                    {error1 && (
                      <Typography variant="body2" color="error">
                        {error1}
                      </Typography>
                    )}
                  </Box>
                )}
                {profile_picture1 &&
                  (profile_picture1.endsWith(".pdf") ? (
                    <div
                      className="upload_pdf_preview"
                      style={{ marginLeft: "16px" }}
                    >
                      <EmbedPDF companyIdentifier="yourcompany">
                        <a
                          href={`${profile_picture1}`}
                          target="_blank"
                          className="pdf_uplod"
                          rel="noopener noreferrer"
                        >
                          <img src="/static/images/pdf.png" alt="img"></img>
                        </a>
                      </EmbedPDF>
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture1("")}
                      />
                    </div>
                  ) : (
                    <div
                      className="upload_image_preview2"
                      style={{ marginLeft: "16px" }}
                    >
                      <CardMedia
                        component="img"
                        image={profile_picture1}
                        alt="photo"
                        sx={{ width: 200, height: 200 }}
                      />
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture1("")}
                      />
                    </div>
                  ))}
                {profile_picture1 &&
                  IdDetails.find(
                    (item: { documentName: number }) => item.documentName === 1
                  ) && (
                    <Button
                      onClick={() =>
                        handleDeleteClick(
                          IdDetails.find(
                            (item: { documentName: number }) =>
                              item.documentName === 1
                          )._id,
                          1
                        )
                      }
                      style={{ marginLeft: "8px" }}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                  )}
              </Grid>

              {/* Document 2 */}
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={showDocument2}
                  onChange={() => handleCheckboxChange(2)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="body1" style={{ marginLeft: "8px" }}>
                  Valid Police Clearance Letter (not older than 4 months from
                  Date of Submission)
                </Typography>
                {/* Add image and PDF upload functionality */}
                {showDocument2 && !profile_picture2 && (
                  <Box
                    className="upload_image_bnr"
                    style={{ marginLeft: "16px" }}
                  >
                    <label htmlFor="icon-button-file-vdo2">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file-vdo2"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg,application/pdf", // Allow images and PDFs
                        }}
                        onChange={(event: any) =>
                          handleImageUpload(event, "icon-button-file-vdo2")
                        }
                      />
                      <Button component="span" className="upload_image_btn">
                        <AddIcon />
                      </Button>
                    </label>
                    {error2 && (
                      <Typography variant="body2" color="error">
                        {error2}
                      </Typography>
                    )}
                  </Box>
                )}
                {profile_picture2 &&
                  (profile_picture2.endsWith(".pdf") ? (
                    <div
                      className="upload_pdf_preview"
                      style={{ marginLeft: "16px" }}
                    >
                      <EmbedPDF companyIdentifier="yourcompany">
                        <a
                          href={`${profile_picture2}`}
                          target="_blank"
                          className="pdf_uplod"
                          rel="noopener noreferrer"
                        >
                          <img src="/static/images/pdf.png" alt="img"></img>
                        </a>
                      </EmbedPDF>
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture2("")}
                      />
                    </div>
                  ) : (
                    <div
                      className="upload_image_preview2"
                      style={{ marginLeft: "16px" }}
                    >
                      <CardMedia
                        component="img"
                        image={profile_picture2}
                        alt="photo"
                        sx={{ width: 200, height: 200 }}
                      />
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture2("")}
                      />
                    </div>
                  ))}
                {profile_picture2 &&
                  IdDetails.find(
                    (item: { documentName: number }) => item.documentName === 2
                  ) && (
                    <Button
                      onClick={() =>
                        handleDeleteClick(
                          IdDetails.find(
                            (item: { documentName: number }) =>
                              item.documentName === 2
                          )._id,
                          2
                        )
                      }
                      style={{ marginLeft: "8px" }}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                  )}
              </Grid>

              {/* Document 3 */}
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Checkbox
                  checked={showDocument3}
                  onChange={() => handleCheckboxChange(3)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="body1" style={{ marginLeft: "8px" }}>
                  Copy of Valid National Identification Front and Back Scan
                </Typography>

                {/* Front Image or PDF Upload */}
                {showDocument3 && !profile_picture3_front && (
                  <Box
                    className="upload_image_bnr"
                    style={{ marginLeft: "16px" }}
                  >
                    <label htmlFor="icon-button-file-vdo3-front">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file-vdo3-front"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg,application/pdf", // Allow images and PDFs
                        }}
                        onChange={(event: any) =>
                          handleImageUpload(
                            event,
                            "icon-button-file-vdo3-front"
                          )
                        }
                      />
                      <Button component="span" className="upload_image_btn">
                        <AddIcon />
                      </Button>
                    </label>
                    {error3_front && (
                      <Typography variant="body2" color="error">
                        {error3_front}
                      </Typography>
                    )}
                  </Box>
                )}
                {profile_picture3_front &&
                  (profile_picture3_front.endsWith(".pdf") ? (
                    <div
                      className="upload_pdf_preview"
                      style={{ marginLeft: "16px" }}
                    >
                      <EmbedPDF companyIdentifier="yourcompany">
                        <a
                          href={`${profile_picture3_front}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="pdf_uplod"
                        >
                          {/* Click to open PDF (Front) */}
                          <img src="/static/images/pdf.png" alt="img"></img>
                        </a>
                      </EmbedPDF>
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture3Front("")}
                      />
                    </div>
                  ) : (
                    <div
                      className="upload_image_preview2"
                      style={{ marginLeft: "16px" }}
                    >
                      <CardMedia
                        component="img"
                        image={profile_picture3_front}
                        alt="photo"
                        sx={{ width: 100, height: 100 }} // Ensure square dimensions
                      />
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture3Front("")}
                      />
                    </div>
                  ))}
                {profile_picture3_front &&
                  IdDetails.find(
                    (item: { documentName: number }) => item.documentName === 3
                  ) && (
                    <Button
                      onClick={() =>
                        handleDeleteClick(
                          IdDetails.find(
                            (item: { documentName: number }) =>
                              item.documentName === 3
                          )._id,
                          3
                        )
                      }
                      style={{ marginLeft: "8px" }}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                  )}

                {/* Back Image or PDF Upload */}
                {showDocument3 && !profile_picture3_back && (
                  <Box
                    className="upload_image_bnr"
                    style={{ marginLeft: "16px" }}
                  >
                    <label htmlFor="icon-button-file-vdo3-back">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file-vdo3-back"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg,application/pdf", // Allow images and PDFs
                        }}
                        onChange={(event: any) =>
                          handleImageUpload(event, "icon-button-file-vdo3-back")
                        }
                      />
                      <Button component="span" className="upload_image_btn">
                        <AddIcon />
                      </Button>
                    </label>
                    {error3_back && (
                      <Typography variant="body2" color="error">
                        {error3_back}
                      </Typography>
                    )}
                  </Box>
                )}
                {profile_picture3_back &&
                  (profile_picture3_back.endsWith(".pdf") ? (
                    <div
                      className="upload_pdf_preview"
                      style={{ marginLeft: "16px" }}
                    >
                      <EmbedPDF companyIdentifier="yourcompany">
                        <a
                          href={`${profile_picture3_back}`}
                          target="_blank"
                          className="pdf_uplod"
                          rel="noopener noreferrer"
                        >
                          {/* Click to open PDF (Back) */}
                          <img src="/static/images/pdf.png" alt="img"></img>
                        </a>
                      </EmbedPDF>
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture3Back("")}
                      />
                    </div>
                  ) : (
                    <div
                      className="upload_image_preview2"
                      style={{ marginLeft: "16px" }}
                    >
                      <CardMedia
                        component="img"
                        image={profile_picture3_back}
                        alt="photo"
                        sx={{ width: 200, height: 200 }} // Ensure square dimensions
                      />
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture3Back("")}
                      />
                    </div>
                  ))}
              </Grid>

              {/* Document 4 */}
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={showDocument4}
                  onChange={() => handleCheckboxChange(4)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="body1" style={{ marginLeft: "8px" }}>
                  Headshot Image with Visible Card having National
                  Identification card Number of the document submitted
                </Typography>
                {/* Add image and PDF upload functionality */}
                {showDocument4 && !profile_picture4 && (
                  <Box
                    className="upload_image_bnr"
                    style={{ marginLeft: "16px" }}
                  >
                    <label htmlFor="icon-button-file-vdo4-front">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file-vdo4-front"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg,application/pdf", // Allow images and PDFs
                        }}
                        onChange={(event: any) =>
                          handleImageUpload(
                            event,
                            "icon-button-file-vdo4-front"
                          )
                        }
                      />
                      <Button component="span" className="upload_image_btn">
                        <AddIcon />
                      </Button>
                    </label>
                    {error4 && (
                      <Typography variant="body2" color="error">
                        {error4}
                      </Typography>
                    )}
                  </Box>
                )}
                {profile_picture4 &&
                  (profile_picture4.endsWith(".pdf") ? (
                    <div
                      className="upload_pdf_preview"
                      style={{ marginLeft: "16px" }}
                    >
                      <EmbedPDF companyIdentifier="yourcompany">
                        <a
                          href={profile_picture4}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="pdf_uplod"
                        >
                          <img src="/static/images/pdf.png" alt="img"></img>
                        </a>
                      </EmbedPDF>
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture4("")}
                      />
                    </div>
                  ) : (
                    <div
                      className="upload_image_preview2"
                      style={{ marginLeft: "16px" }}
                    >
                      <CardMedia
                        component="img"
                        image={profile_picture4}
                        alt="photo"
                        sx={{
                          width: 200,
                          height: 200,
                          objectFit: "cover", // Ensure image is properly scaled
                          borderRadius: "4px", // Optional styling for rounded corners
                        }}
                      />
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture4("")}
                      />
                    </div>
                  ))}
                {profile_picture4 &&
                  IdDetails.find(
                    (item: { documentName: number }) => item.documentName === 4
                  ) && (
                    <Button
                      onClick={() =>
                        handleDeleteClick(
                          IdDetails.find(
                            (item: { documentName: number }) =>
                              item.documentName === 4
                          )._id,
                          4
                        )
                      }
                      style={{ marginLeft: "8px" }}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                  )}
              </Grid>

              {/* <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox
                checked={showDocument4}
                onChange={() => handleCheckboxChange(4)}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="body1" style={{ marginLeft: "8px" }}>
                Valid National Identification Front Scan
              </Typography>
              {showDocument4 && !profile_picture4 && (
                <Box className="upload_image_bnr" style={{ marginLeft: "16px" }}>
                  <label htmlFor="icon-button-file-vdo4-front">
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file-vdo4-front"
                      type="file"
                      inputProps={{
                        accept: "image/png,image/jpeg",
                      }}
                      onChange={(event: any) =>
                        handleImageUpload(event, "icon-button-file-vdo4-front")
                      }
                    />
                    <Button component="span" className="upload_image_btn">
                      <AddIcon />
                    </Button>
                  </label>
                </Box>
              )}
              {profile_picture4 && (
                <div className="upload_image_preview2">
                  <CardMedia component="img" image={profile_picture4} alt="photo" />
                  <CancelIcon onClick={() => setProfilePicture4("")} />
                </div>
              )}
            </Grid> */}

              {/* Document 5 */}
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={showDocument5}
                  onChange={() => handleCheckboxChange(5)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography variant="body1" style={{ marginLeft: "8px" }}>
                  Scan Facial biometric (Selfie Scan)
                </Typography>
                {/* Add image and PDF upload functionality */}
                {showDocument5 && !profile_picture5 && (
                  <Box
                    className="upload_image_bnr"
                    style={{ marginLeft: "16px" }}
                  >
                    <label htmlFor="icon-button-file-vdo4-back">
                      <Input
                        sx={{ display: "none" }}
                        id="icon-button-file-vdo4-back"
                        type="file"
                        inputProps={{
                          accept: "image/png,image/jpeg,application/pdf",
                        }}
                        onChange={(event: any) =>
                          handleImageUpload(event, "icon-button-file-vdo4-back")
                        }
                      />
                      <Button component="span" className="upload_image_btn">
                        <AddIcon />
                      </Button>
                    </label>
                    {error5 && (
                      <Typography variant="body2" color="error">
                        {error5}
                      </Typography>
                    )}
                  </Box>
                )}
                {profile_picture5 &&
                  (profile_picture5.endsWith(".pdf") ? (
                    <div
                      className="upload_pdf_preview"
                      style={{ marginLeft: "16px" }}
                    >
                      <EmbedPDF companyIdentifier="yourcompany">
                        <a
                          href={profile_picture5}
                          target="_blank"
                          className="pdf_uplod"
                          rel="noopener noreferrer"
                        >
                          <img src="/static/images/pdf.png" alt="img"></img>
                        </a>
                      </EmbedPDF>
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture5(null)}
                      />
                    </div>
                  ) : (
                    <div
                      className="upload_image_preview5"
                      style={{ marginLeft: "16px" }}
                    >
                      <CardMedia
                        component="img"
                        image={profile_picture5}
                        alt="Selfie Scan"
                        sx={{ width: 200, height: 200 }}
                      />
                      <CancelIcon
                        style={{
                          color: "red",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => setProfilePicture5(null)}
                      />
                    </div>
                  ))}
                {profile_picture5 &&
                  IdDetails.find(
                    (item: { documentName: number }) => item.documentName === 5
                  ) && (
                    <Button
                      onClick={() =>
                        handleDeleteClick(
                          IdDetails.find(
                            (item: { documentName: number }) =>
                              item.documentName === 5
                          )._id,
                          5
                        )
                      }
                      style={{ marginLeft: "8px" }}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                  )}
              </Grid>
            </Grid>
            <div style={{ marginTop: 20 }} />
            {/* Submit Button */}
            <div className="form_btn">
              <Button
                size="large"
                className="btn btn_primary"
                onClick={handleAddClick}
              >
                Add
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Idproof;
