// import React, { ChangeEvent, useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   CardMedia,
//   Grid,
//   Input,
//   TextField,
//   Typography,
// } from "@mui/material";
// import CancelIcon from "@mui/icons-material/Cancel";
// import AddIcon from "@mui/icons-material/Add";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { UploadMedia } from "../../utils/uploadMedia";
// import { errorToast, successToast } from "../../helpers";
// import { useLazyDeleteDocsByIdQuery, useLazyGetIdProofByIdQuery, usePostaddDocumentsMutation } from "../../services/documentation";
// import { CommonBody } from "../../types/General";
// import { generateEncryptedKeyBody } from "../../utils/crypto";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useUpdateIdProofMutation } from "../../services/documentation";

// const Documentation = () => {
//   const [achievementsImage, setAchievementsImage] = useState<string[]>([]);
//   const [achievementTitle, setAchievementTitle] = useState<string>("");
//   const [achievementDescription, setAchievementDescription] = useState<string>("");
//   const [items, setItems] = useState<any>([]);
//  const[achieve,setAachievementsImage]=useState<string[]>([]);
//   const [subValue, setSubValue] = useState("");
//   const [updaetDocById] = useUpdateIdProofMutation();
//   const navigate = useNavigate();
//   const [labelsAndImages, setLabelsAndImages] = useState<{ label: string; image: string }[]>([]);
//   const [error, setError] = useState(false);
//   const[AchievementsDetails,setAchievementsDetails]=useState<any>([]);
//   const [video, setVideo] = useState<string | null>(null);
//   const [video2, setVideo2] = useState<string | null>(null);
//   const [videoTitle, setVideoTitle] = useState<string>("");
//   const [videoTitle2, setVideoTitle2] = useState<string>("");
//   const [error2, setError2] = useState(false);

//   const [vdoDescription, setVdoDescription] = useState<string>("");
//   const [vdoDescription2, setVdoDescription2] = useState<string>("");
//   console.log("whduwhdhwudhuhd", vdoDescription);
//   // const [vdoDescription2, setVdoDescription] = useState<string>("");

//   const [items2, setItems2] = useState<any>([]);
//   const [deleteDocById] = useLazyDeleteDocsByIdQuery();
//   const [profile_picture, setProfilePicture] = useState<any>(null);
//   console.log("wddd", profile_picture);
//   const [adddocUMENT] = usePostaddDocumentsMutation();
//   const [certificationsImage, setCertificationsImage] = useState<string[]>([]);
//   const [certificationTitle, setCertificationTitle] = useState<string>("");
//   const [profile_picture2, setProfilePicture2] = useState<any>(null);
//   const [tutorId, setTutorId] = useState<string | null>(null);
//   console.log("furff", tutorId);
//   const location = useLocation();
//   const { state } = location;
//   const { _id } = useParams();
//   console.log("xbecececed",_id);
//   const [imageId, setImageId] = useState(null);
//   const[getIdProofById]=useLazyGetIdProofByIdQuery();
//   const [certificationImage, setCertificationImage] = useState<string | null>(null);
// const [certificationError, setCertificationError] = useState<boolean>(false);
// // const handleCertifications = (event: ChangeEvent<HTMLInputElement>) => {
// //   const files = event.target.files;
// //   if (files && files[0].type.includes("image")) {
// //     const certification = URL.createObjectURL(files[0]);
// //     setCertificationImage(certification);
// //   } else {
// //     setCertificationError(true); // Set error if file type is not supported
// //   }
// // };

//   const [imageId2, setImageId2] = useState(null);
//   const handleAddClick = async () => {
//     if (!profile_picture || !vdoDescription.trim()) {
//       setError(true);
//       return;
//     }

//     try {
//       const payload = {
//         tutorId: _id ? _id : tutorId,
//         documents: [
//           {
//             frontImage: profile_picture || "",
//             description: vdoDescription,
//             documentName: 1,
//             documentType: 4,
//           }

//         ]
//       };

//       const encryptedBody = await generateEncryptedKeyBody(payload) as CommonBody;

//       const response = await adddocUMENT(encryptedBody).unwrap();

//       if (response.statusCode === 200) {
//         successToast("Achievement Docs added successfully");
//         // Update state and reset fields
//         // const newItem = {
//         //   _id: items.length.toString(),
//         //   title: videoTitle,
//         //   link: certificationImage,
//         //   description: vdoDescription,
//         // };

//         const newItem = {
//           _id: items2.length.toString(),
//           title: videoTitle,
//           link: profile_picture,
//           description: vdoDescription,
//         };
//         setItems([newItem, ...items]);
//         setProfilePicture(null);
//         setVideoTitle("");
//         setVdoDescription(""); // Reset vdoDescription2

//         setCertificationError(false);
//         setError(false);
//       } else {
//         errorToast("Failed to add certification documents. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error adding certification documents:", error);
//       errorToast("Failed to add certification documents. Please try again.");
//     }
//   };
//   const handleAchievements = (event: ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     if (files && files[0].type.includes("image")) {
//       const newAchievements = Array.from(files).map((file) => URL.createObjectURL(file));
//       setAachievementsImage((prevCertifications) => [...prevCertifications, ...newAchievements]);
//     }
//   };
// ////danger

// const fetchDataById = async (tutorId: string, documentType?: number) => {
//   try {
//     const response = await getIdProofById({ userId: tutorId, documentType:1 }).unwrap();
//     if (response.statusCode === 200) {
//       setAchievementsDetails(response.data);
//       // Set checkboxes and images based on response
//       response.data.forEach((item: any) => {
//         switch (item.documentName) {
//           case 1:

//             setProfilePicture(item.frontImage);
//             break;
//           case 2:

//             setProfilePicture2(item.frontImage);
//             break;

//           default:
//             break;
//         }
//       });
//     }
//   } catch (error) {
//     console.error(error);
//   }
// }

// useEffect(() => {
//   if (_id) {
//     fetchDataById(_id);
//   }
// }, [_id]);

// //////edfefr

//   const handleAddAchievementClick = async () => {
//     // Validation logic for adding achievements
//     if (!achievementTitle || !achievementDescription || achievementsImage.length === 0) {
//       // Handle error
//       return;
//     }

//     // Logic to add achievement
//     const newAchievement = {
//       _id: items.length.toString(),
//       title: achievementTitle,
//       description: achievementDescription,
//       images: achievementsImage,
//     };

//     // Update state with new achievement
//     setItems([...items, newAchievement]);

//     // Reset form fields
//     setAchievementTitle("");
//     setAchievementDescription("");
//     setAchievementsImage([]);
//   };

//   const handleAddClick2 = async () => {
//     if (!profile_picture2 || !vdoDescription2.trim()) {
//       setError2(true);
//       return;
//     }

//     try {

//       const payload = {
//         tutorId: _id ? _id : tutorId,
//         documents: [
//           {
//             frontImage: profile_picture2 || "",
//             description: vdoDescription2,
//             documentName: 1,
//             documentType: 4,
//           }
//         ]
//       };

//       // Generate encrypted body
//       const encryptedBody = await generateEncryptedKeyBody(payload) as CommonBody;

//       // Call the API to add documents
//       const response = await adddocUMENT(encryptedBody).unwrap();

//       // Check the response status code
//       if (response.statusCode === 200) {
//         successToast("Certification Docs added successfully");
//         // Update state and reset fields
//         const newItem2 = {
//           _id: items2.length.toString(),
//           title: videoTitle2,
//           link: profile_picture2,
//           description: vdoDescription2,
//         };
//         setItems2([newItem2, ...items2]);
//         setProfilePicture2(null);
//         setVideoTitle2("");
//         setVdoDescription2(""); // Reset vdoDescription2
//         setError2(false);
//       } else {
//         errorToast("Failed to add certification documents. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error adding certification documents:", error);
//       errorToast("Failed to add certification documents. Please try again.");
//     }
//   };

//   const handleImageUpload = async (
//     event: React.ChangeEvent<HTMLInputElement>,
//     type: string
//   ) => {
//     const files = event.target.files;
//     const file = files?.length ? files[0] : null;

//     try {
//       if (!file) {
//         if (type === 'profile_picture') {
//           formik1.setFieldValue("profile_picture", "");
//           setProfilePicture("");
//           setImageId(null);
//         } else if (type === 'profile_picture2') {
//           formik2.setFieldValue("profile_picture2", "");
//           setProfilePicture2("");
//           setImageId2(null);
//         }
//         return;
//       }

//       const res = await UploadMedia(file);
//       if (res?.statusCode === 200) {
//         if (type === 'profile_picture') {
//           formik1.setFieldValue("profile_picture", res?.data?.image);
//           setProfilePicture(res?.data?.image);
//         } else if (type === 'profile_picture2') {
//           formik2.setFieldValue("profile_picture2", res?.data?.image);
//           setProfilePicture2(res?.data?.image);
//         }
//       } else {
//         errorToast(res?.message);
//       }
//     } catch (error) {
//       console.error("Error uploading image:", error);
//       errorToast("Error uploading image. Please try again.");
//     }
//   };

//   const handleDeleteClick = (_id: string) => {
//     const updatedItems = items.filter((item: any) => item._id !== _id);
//     setItems(updatedItems);
//   };
//   const handleDeleteClick2 = (_id: string) => {
//     const updatedItems = items2.filter((item: any) => item._id !== _id);
//     setItems2(updatedItems);
//   };
//   const handleCertifications = (event: ChangeEvent<HTMLInputElement>) => {
//     const files = event.target.files;
//     if (files && files[0].type.includes("image")) {
//       const newCertifications = Array.from(files).map((file) => URL.createObjectURL(file));
//       setCertificationsImage((prevCertifications) => [...prevCertifications, ...newCertifications]);
//     }
//   };

//   const formik1 = useFormik({
//     initialValues: {
//       video: video || "",
//       videoTitle: videoTitle || "",
//     },
//     onSubmit: handleAddClick,
//   });

//   const formik2 = useFormik({
//     initialValues: {
//       video2: video2 || "",
//       videoTitle2: videoTitle2 || "",
//     },
//     onSubmit: handleAddClick2,
//   });

//   // const handleAchievements = (event: ChangeEvent<HTMLInputElement>) => {
//   //   const files = event.target.files;
//   //   if (files && files[0].type.includes("image")) {
//   //     const newAchievements = Array.from(files).map((file) => URL.createObjectURL(file));
//   //     setAchievementsImage((prevAchievements) => [...prevAchievements, ...newAchievements]);
//   //   }
//   // };
//   // const handleAchievements = (event: ChangeEvent<HTMLInputElement>) => {
//   //   const files = event.target.files;
//   //   if (files && files[0].type.includes("image")) {
//   //     const newAchievements = Array.from(files).map((file) => URL.createObjectURL(file));
//   //     setAchievementsImage((prevCertifications) => [...prevCertifications, ...newAchievements]);
//   //   }
//   // };

//   const handleAddSubjects = () => {
//     if (subValue && achievementsImage.length > 0) {
//       setLabelsAndImages((prevLabelsAndImages) => [
//         ...prevLabelsAndImages,
//         { label: subValue, image: achievementsImage[achievementsImage.length - 1] },
//       ]);
//       setSubValue("");
//       setAchievementsImage([]);
//     }
//   };
//   useEffect(() => {
//     const storedTutorId = localStorage.getItem("tutorId");
//     if (storedTutorId) {
//       setTutorId(storedTutorId);
//     }
//   }, []);
//   return (
//     <div className="main_layout">
//       <div className="dashboard">
//         {state?.type === "edit" ? (
//           <h1 className="mn_hdng">Edit Tutor</h1>
//         ) : (
//           <h1 className="mn_hdng">Documentation</h1>
//         )}
//         <Button
//           className="btn btn_primary"
//           onClick={() => {
//             navigate("/manage-tutors");
//           }}
//         >
//           Back
//         </Button>
//       </div>
//       <Card className="cards">
//         <form onSubmit={formik1.handleSubmit}>

//           <CardContent>
//             <div className="card_main">
//               {items &&
//                 items.map((item: any, i: number) => (
//                   <div key={item?._id} className="card">
//                     <Button onClick={() => handleDeleteClick(item?._id)}>
//                       <DeleteIcon />
//                     </Button>

//                     {item?.link && (
//                       <figure>
//                         <img src={item.link} style={{ height: "100px", width: "80px" }} />
//                       </figure>
//                     )}
//                     <div className="desc">
//                       <h4>{item?.title}</h4>
//                       <p>{item?.description || ""}</p>
//                       {/* <p className="time">Oct 22, 2023</p> */}
//                     </div>
//                   </div>
//                 ))}
//             </div>

//             <Grid container spacing={2}>
//               <Grid item lg={6} md={6} sm={6} xs={12}>
//                 <Typography className="custom_label" variant="h5" sx={{ paddingTop: "16px", width: "40%" }}>
//                   Achievements

//                 </Typography>
//                 <TextField
//                   hiddenLabel
//                   type="text"
//                   name="videoDescription"
//                   variant="outlined"
//                   fullWidth
//                   placeholder="Add Achievement"
//                   value={vdoDescription}
//                   onChange={(e) => setVdoDescription(e.target.value)}
//                 />
//                 {error && !vdoDescription && <h6 className="err_msg">This field is compulsory.</h6>}
//               </Grid>
//               <Grid item lg={6} md={6} sm={6} xs={12}></Grid>

//               <Grid item lg={6} md={6} sm={6} xs={12}>
//                 <Typography className="custom_label"></Typography>

//                 {profile_picture ? (
//                   <div className="upload_image_preview2">
//                     <CardMedia component="img" image={profile_picture} alt="photo" />
//                     <CancelIcon onClick={() => setProfilePicture("")} color="error" />
//                   </div>
//                 ) : (
//                   <Box className="upload_image_bnr">
//                     <label htmlFor="icon-button-file-vdo">
//                       {/* Use a unique id */}
//                       <Input
//                         sx={{ display: "none" }}
//                         id="icon-button-file-vdo"
//                         type="file"
//                         inputProps={{
//                           accept: "image/png,image/jpeg",
//                         }}
//                         onChange={(e: any) => handleImageUpload(e, "profile_picture")}
//                       />
//                       <Button component="span" className="upload_image_btn">
//                         <AddIcon />
//                       </Button>
//                     </label>
//                   </Box>
//                 )}

//                 {error && !profile_picture && (
//                   <h6 className="err_msg">This field is compulsory.</h6>
//                 )}
//               </Grid>
//               <div style={{ marginTop: 20 }} />
//             </Grid>

//             <Grid item lg={8} md={8} sm={8} xs={8} />

//             <Grid item lg={8} md={8} sm={8} style={{ paddingTop: "8px" }}>
//               {items?.length < 100 ? (
//                 <div className="form_btn">
//                   <Button
//                     size="large"
//                     type="button"
//                     className="btn btn_primary"
//                     onClick={handleAddClick}
//                   >
//                     Add
//                   </Button>
//                 </div>
//               ) : null}
//             </Grid>
//           </CardContent>
//         </form>
//         <form onSubmit={formik2.handleSubmit}>
//           <CardContent>
//             <div className="card_main">
//               {items2 &&
//                 items2.map((item: any, i: number) => (
//                   <div key={item?._id} className="card">
//                     <Button onClick={() => handleDeleteClick2(item?._id)}>
//                       <DeleteIcon />
//                     </Button>

//                     {item?.link && (
//                       <figure>
//                         <img src={item.link} style={{ height: "100px", width: "100px" }} />
//                       </figure>
//                     )}
//                     <div className="desc">
//                       <h4>{item?.title}</h4>
//                       <p>{item?.description || ""}</p>
//                       {/* <p className="time">Oct 22, 2023</p> */}
//                     </div>
//                   </div>
//                 ))}
//             </div>
//             <Grid container spacing={2}>
//               <Grid item lg={6} md={6} sm={6} xs={12}>
//                 <Typography className="custom_label" variant="h5" sx={{ paddingTop: "16px" }}>
//                   Certifications
//                 </Typography>

//                 <TextField
//                   hiddenLabel
//                   type="text"
//                   name="videoDescription2"
//                   variant="outlined"
//                   fullWidth
//                   placeholder="Add Description"
//                   value={vdoDescription2}
//                   onChange={(e) => setVdoDescription2(e.target.value)}
//                 />

//                 {error2 && !vdoDescription2 && (
//                   <h6 className="err_msg">This field is compulsory.</h6>
//                 )}
//               </Grid>
//               <Grid item lg={6} md={6} sm={6} xs={12}></Grid>

//               <Grid item lg={6} md={6} sm={6} xs={12}>
//                 <Typography className="custom_label"></Typography>

//                 {profile_picture2 ? (
//                   <div className="upload_image_preview2">
//                     <CardMedia component="img" image={profile_picture2} alt="photo" />
//                     <CancelIcon onClick={() => setProfilePicture2("")} color="error" />

//                   </div>
//                 ) : (
//                   <Box className="upload_image_bnr">
//                     <label htmlFor="icon-button-file-vdo2">
//                       {/* Use a unique id */}
//                       <Input
//                         sx={{ display: "none" }}
//                         id="icon-button-file-vdo2"
//                         type="file"
//                         inputProps={{
//                           accept: "image/png,image/jpeg",
//                         }}
//                         onChange={(e: any) => handleImageUpload(e, "profile_picture2")}
//                       />
//                       <Button component="span" className="upload_image_btn">
//                         <AddIcon />
//                       </Button>
//                     </label>
//                   </Box>
//                 )}

//                 {error2 && !profile_picture2 && (
//                   <h6 className="err_msg">This field is compulsory.</h6>
//                 )}
//               </Grid>
//               <div style={{ marginTop: 20 }} />
//             </Grid>
//             <Grid item lg={8} md={8} sm={8} style={{ paddingTop: "8px" }}>
//               {items2?.length < 100 ? (
//                 <div className="form_btn">
//                   <Button
//                     size="large"
//                     type="button"
//                     className="btn btn_primary"
//                     onClick={handleAddClick2}
//                   >
//                     Add
//                   </Button>
//                 </div>
//               ) : null}
//             </Grid>

//             <div className="form_btn">
//             <Button
//   size="large"
//   type="button"
//   className="btn btn_primary"
//   onClick={() => {
//     setError2(true);
//     setError(true);
//   }}
// >
//   Submit
// </Button>

//             </div>
//           </CardContent>
//         </form>
//       </Card>
//     </div>
//   );
// }

// export default Documentation;

import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card, Box, Button, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Accepted from "../../features/tutor/Accepted";
import Rejected from "../../features/tutor/Rejected";
import Pending from "../../features/tutor/Pending";
import {
  useLazyDeleteTutorByIdQuery,
  useLazyGetAllTutorListingQuery,
} from "../../services/tutor";
import { errorToast, successToast } from "../../helpers";
import { useLazyTutorCsvQuery } from "../../services/tutor";
import Achievements from "./Achievements";
import Cerrifications from "./Cerrifications";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Documentation = () => {
  const navigate = useNavigate();
  const [exportCsv] = useLazyTutorCsvQuery();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [value, setValue] = useState<number>(1);
  const [tututorStatust, settututorStatust] = useState<number>(1);
  const [rows, setRows] = useState<any>([]);
  const [getAllTutorList, { isLoading }] = useLazyGetAllTutorListingQuery();
  const [deleteById] = useLazyDeleteTutorByIdQuery();
  const { _id } = useParams(); // Accessing the _id parameter from the URL
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="main_loyout">
      {/* <div className="dashboard">
          <h1 className="mn_hdng">Documentations</h1>
        </div> */}
      <Card className="cards">
        {/* <Box className="custom_tabs"> */}
        <Box className="flx_sc">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="Experience" {...a11yProps(0)} />
            <Tab label="Education Background" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Achievements />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Cerrifications />
        </CustomTabPanel>

        {/* </Box> */}
      </Card>
    </div>
  );
};

export default Documentation;
