import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    Input,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/bootstrap.css";
  import { useEffect, useState } from "react";
  import MainContainer from "../../layout/MainContainer";
  import { useFormik } from "formik";
  import * as Yup from "yup";
   
  import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { isNumber, isNumberWithDot } from "../../utils/validation";
import { useAddSettingsMutation, useLazyDisplaysettingsQuery } from "../../services/settings";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";
import Loader from "../../helpers/Loader";
  const Settings = () => {
    const [image, setImage] = useState<string>("");
    const [category, setCategory] = useState('1');
    const [serviceType,setServiceType] = useState('1');
const[settings]=useAddSettingsMutation();
const[displaySettings,{isLoading}]=useLazyDisplaysettingsQuery();
    const validationSchema = Yup.object().shape({
      
      Amount: Yup.number().required("Amount is required"),
      ServiceFees : Yup.string().required("Service Fees is required")
    });
  
    const formik = useFormik({
      initialValues: {
        Amount: "",
      ServiceFees:""
      },
      validationSchema: validationSchema,
      onSubmit: async(values) => {
        const body = {
          distanceType:parseInt(category),
          distanceAmount: parseFloat(values?.Amount),
          serviceFees:parseFloat(values?.ServiceFees),
          serviceType: parseInt(serviceType)
        };
        console.log(body);
        const encryptedBody=generateEncryptedKeyBody(body) as CommonBody;
        try{
          const respones=await settings(encryptedBody).unwrap();
          if(respones.statusCode==200)
            {
              successToast("Settings updated successfully");
            }
        }
        catch(error:any)
        {
          errorToast(error?.data?.message);
        }
        // console.log("Body_profile:", body);
      },
    });


const getSettings=async()=>{
    const response=await displaySettings({}).unwrap();
    if(response.statusCode==200)
    {
      console.log(response);
      setCategory(response?.data?.distanceType);
      setServiceType(response?.data?.serviceType);
      formik.setFieldValue('Amount',response?.data?.distanceAmount);
      formik.setFieldValue('ServiceFees',response?.data?.serviceFees);
    }
}
    useEffect(()=>{
      getSettings();
    },[])
    return (
      <MainContainer>
        <div className="main_layout">
          <div className="dashboard">
            <h1>Settings</h1>
          </div>
          <Loader isLoad={isLoading} />
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
               
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Typography className="custom_label">Distance Type</Typography>
                  <Select
                    className="select_div "
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {/* <MenuItem value="" disabled>
                      Select
                    </MenuItem> */}
                    <MenuItem value={1}>Kilometer</MenuItem>
                    <MenuItem value={2}>Meter</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth>
                  <Typography className="custom_label">Sevice Type</Typography>
                  <Select
                    className="select_div "
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    displayEmpty
                    value={serviceType}
                    onChange={(e) => setServiceType(e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value={1}>Flat</MenuItem>
                    <MenuItem value={2}>Percentage</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
        

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Distance Amount</Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="Amount"
                      variant="outlined"
                      fullWidth
                      placeholder="Amount"
                      value={formik.values.Amount}
                       onChange={(e) => {
                        if (isNumberWithDot(e.target.value)) {
                          formik.handleChange(e)
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.Amount && Boolean(formik.errors.Amount)}
                      helperText={formik.touched.Amount && formik.errors.Amount}
                    />
                  </Grid>
                
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Service Fees</Typography>
                    <TextField
                      hiddenLabel
                      
                      name="ServiceFees"
                      variant="outlined"
                      fullWidth
                      placeholder="Service Fees"
                      value={formik.values.ServiceFees}
                      onChange={(e) => {
                        if ((e.target.value)) {
                          formik.handleChange(e)
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.ServiceFees && Boolean(formik.errors.ServiceFees)}
                      helperText={formik.touched.ServiceFees && formik.errors.ServiceFees}
                    />
                  </Grid>
                 
                </Grid>
                <div className="form_btn">
                  <Button size="large" type="submit" className="btn btn_primary">
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    );
  };
  
  export default Settings;
  