import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { errorToast, successToast } from "../../../helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useResetPasswordMutation } from "../../../services/auth";
import Loader from "../../../helpers/Loader";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleshowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .label("New Password")
        .required("New password is required.")
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (@$!%*?&)"
        )
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be the same as the current password."
        ),
  
      passwordConfirmation: Yup.string()
        .oneOf(
          [Yup.ref("newPassword")],
          "Passwords must match."
        )
        .required("Confirm password is required."),
    }),
  
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        newPassword: formik.values.newPassword,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await resetPassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Password reset successfully" || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
      formik.setSubmitting(false);
      navigate("/");
    },
  });

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {/* <h1>LOGO</h1> */}
          <figure className="logo_cntnr" style={{ backgroundColor: "white", marginBottom: 10, padding: 10, borderRadius: "20px" }}>
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/forgotpassword")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Reset Password
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                sx={{ m: 0 }}
                hiddenLabel
                placeholder="Password"
                fullWidth
                margin="normal"
                name="newPassword"
                id="newPassword"
                className="text_field"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Confirm Password</Typography>
              <TextField
                sx={{ m: 0 }}
                className="text_field"
                hiddenLabel
                placeholder="Confirm Password"
                fullWidth
                margin="normal"
                name="passwordConfirmation"
                id="passwordConfirmation"
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleshowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default ResetPassword;
