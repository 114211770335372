import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { useLazyGetSpecificReviewByIdQuery, useLazyGetSpecificReviewPageByIdQuery } from "../../services/review";
import Loader from "../../helpers/Loader";

const ReviewDetails = () => {
  const navigate = useNavigate();
  const [rating, setRating] = useState<number>(0);
  const [review, setReview] = useState<string>("");

  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { _id } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  // const [deleteUserById] = useLazyDeleteByIduserQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [fetchReview,{isLoading}]=useLazyGetSpecificReviewPageByIdQuery();
 const[ByIdDetails,setByIdDetails]=useState<any>([]);
const[tutor,setTutor]=useState<any>([]);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  let totalPages = Math.ceil(totalCount / 10);
  const fetchDataById = async (_id: any) => {
    try {
      const response = await fetchReview({ userId: _id }).unwrap();
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data||{});
        const data = response.data;
        setRating(data.rating || 0);
        setReview(data.review || "");
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(()=>{
fetchDataById(_id)
  },[]);
  
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Review Details</h1>
          <Loader isLoad={isLoading} />
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-reviews");
            }}
          >
            Back
          </Button>
          
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item xs={10} className="view_box_list">
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Review By</Typography>
                    <Typography component="p">{ByIdDetails?.parents?.[0]?.name||"-"}</Typography>

                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Service Provider Name
                    </Typography>
                    <Typography component="p">{ByIdDetails?.tutors?.[0]?.name ||"-"}</Typography>

                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Rating</Typography>
                    <Rating
                      initialValue={rating}
                      iconsCount={5}
                      size={23}
                      allowHover={false}
                      readonly
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <Typography component="h5">Review</Typography>
                    <Typography component="p">{review ||"-"}</Typography>
                  </Box>
                </Grid>
              </Grid>
              </Grid>
        
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ReviewDetails;
