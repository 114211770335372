import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { useLazyBookingCsvQuery, useLazyGetAllPaymentsQuery } from "../../services/booking";
import { isValidInput } from "../../utils/validation";
import Pagination from "../../components/Pagination";
import Loader from "../../helpers/Loader";
import { errorToast, successToast } from "../../helpers/toast";

const ManagePayment = () => {
  const navigate = useNavigate();
  const [fetchBooking, { isLoading }] = useLazyGetAllPaymentsQuery();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [rows, setRows] = useState<any>([]);
  console.log("ewververcv",rows);
  const[exportCsv]=useLazyBookingCsvQuery();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  let totalPages = Math.ceil(totalCount / 10);
  const handleExportCsv = async () => {
    try {
      const res = await exportCsv({}).unwrap();
      console.log("eugded", res);
  
     
      const parsedRes = typeof res === "string" ? JSON.parse(res) : res;
  
     
      if (parsedRes && parsedRes.message && parsedRes.message.s3Location) {
        window.open(parsedRes.message.s3Location);
        successToast("csv exported successfully");
      } else {
       
        errorToast("Error: s3Location not found in response");
      }
    } catch (error: any) {
      errorToast(error?.message);
    }
  };
  const fetchData = async (page: number, search: string) => {
    try {
      const response = await fetchBooking({ page, search, bookingType: 7 }).unwrap();
      if (response.statusCode === 200) {
        setRows(response?.data?.booking);
        };
      
        setTotalCount(response.data.totalBooking);
      }  
     catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(page, debouncedSearchTerm);
  }, [page, debouncedSearchTerm]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setRows([]);
  };

  return (
    <MainContainer>
      <Loader isLoad={isLoading}/>
     
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Payment</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
            <Button className="btn btn_primary" onClick={handleExportCsv}>
              <FileDownloadIcon />Export CSV</Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Booking Id</TableCell>
                  <TableCell>Transaction Id</TableCell>
                  {/* <TableCell>Payment Received</TableCell> */}
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Amount</TableCell>
                  {/* <TableCell>Phone Number</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any, index: number) => (
                  <TableRow key={row.Order_ID}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell>{row?.bookingNumber||"-"}</TableCell>
                    <TableCell>{row?.OrderTrackingId||"-"}</TableCell>
                    {/* <TableCell>{row.Payment_Received}</TableCell> */}
                    <TableCell>{row?.parents?.name||"-"}</TableCell>
                    <TableCell>{row?.parents?.email||"-"}</TableCell>
                    <TableCell> {`$ `+ row?.grandTotal? row?.grandTotal?.toFixed(2):0}</TableCell>
                    {/* <TableCell>{row.Phone_Number}</TableCell> */}
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton onClick={() => navigate(`/paymentDetails/${row?._id}`)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      {rows?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={rows}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default ManagePayment;
