import emptySplitApi from "../utils/rtk";
import { User } from "../types/User";
import { END_POINTS } from "../helpers";
import { CommonBody, TutorInfo } from "../types/General";

type CommonResponseType = {
  data: any;
  statusCode: number;
  message: string;
};

type PostLoginBody = {
  email: string;
  password: string;
};
interface withdrawStatusBody {
  encryptedBody?: any;  
  body?:any,
  userId?:any
}


type withdrawStatusParams = {
   userId?: string|undefined;
encryptedBody?:CommonBody;
page?:number
}

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
   

    getAllTutorListing: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string; tutorStatus?: string }>({
      query: ({ page, search, tutorStatus }) => {
        let url = END_POINTS.getTutor;
        const queryParams = [];
    
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (search) {
          queryParams.push(`search=${search}`);
        }
        if (tutorStatus) {
          queryParams.push(`tutorStatus=${tutorStatus}`);
        }
    
        if (queryParams.length > 0) {
          url += `?${queryParams.join('&')}`;
        }
        
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getAllPaymentRequest: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string }>({
      query: ({ page, search }) => {
        let url = END_POINTS.tutorPayment;
      url+=`?status=1`
        if (page && search) {
          url += `&page=${page}&search=${search}`;
        } else if (page) {
          url += `&page=${page}`;
        } else if (search) {
          url += `&search=${search}`;
        }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getApprovedPaymentRequest: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string }>({
      query: ({ page, search }) => {
        let url = END_POINTS.tutorPayment;
      url+=`?status=2`
        if (page && search) {
          url += `&page=${page}&search=${search}`;
        } else if (page) {
          url += `&page=${page}`;
        } else if (search) {
          url += `&search=${search}`;
        }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getRejectedPaymentRequest: builder.query<CommonResponseType & { data: any }, { page?: number; search?: string }>({
      query: ({ page, search }) => {
        let url = END_POINTS.tutorPayment;
      url+=`?status=3`
        if (page && search) {
          url += `&page=${page}&search=${search}`;
        } else if (page) {
          url += `&page=${page}`;
        } else if (search) {
          url += `&search=${search}`;
        }
       
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    // PutupdateTutot: builder.query<CommonResponseType & { data: any }, { userId: any}>
    // ({
    //   query: ({ userId }) => ({
    //     url: `${END_POINTS.updateTutor}/${userId}`,
    //     method: "GET"
    //   })
    // }),
    getTeachingDetailsById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.getTeachingDetails}/${userId}`,
        method: "GET"
      })
    }),
    getutorById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `${END_POINTS.getTutor}/${userId}`,
        method: "GET"
      })
    }),
    getTutorDetails: builder.query<CommonResponseType & { data: any }, { id: string}>
    ({
      query: ({ id }) => ({
        url: `${END_POINTS.getTutorDetails}/${id}`,
        method: "GET"
      })
    }),
  //   putTutorProfile: builder.mutation<
  //   CommonResponseType & { data: any },
  //   { body: CommonBody; userId: string|[] }
  // >({
  //   query: ({ body, userId }) => ({
  //     url: `${END_POINTS.updateTutor}/${userId}`,
  //     method: "PUT",
  //     body,
  //   }),
  // }),
  putTutorProfile: builder.mutation<CommonResponseType, withdrawStatusBody>({
    query: ({ encryptedBody }) => ({
      url: `${END_POINTS.updateTutor}`,
      method: 'PUT',
      body: encryptedBody,
    }),
  }), 
  putUpdateProfileTutor: builder.mutation<
  CommonResponseType & { data: any },
  { body: CommonBody; userId: string }
>({
  query: ({ body, userId }) => ({
    url: `${END_POINTS.tutorUpdate}/${userId}`,
    method: "PUT",
    body,
  }),
}),
  putTeachingDetails: builder.mutation<
  CommonResponseType & { data: any },
  { body: CommonBody; userId: string }
>({
  query: ({ body, userId }) => ({
    url: `${END_POINTS.teachingDetails}/${userId}`,
    method: "PUT",
    body,
  }),
}),
putBulkTutorProfile: builder.mutation<
CommonResponseType & { data: any },
{ body: CommonBody }
>({
query: ({ body }) => ({
  url: `${END_POINTS.updateTutor}`,  
  method: 'PUT',
  body,
}),
}),
getReviewById: builder.query<CommonResponseType & { data: any }, { userId: any }>
({
  query: ({ userId}) => ({
    url: `${END_POINTS.tutorReview}/${userId}`,
    method: "GET"
  })
}),
  PostTutorTeachingDetails: builder.mutation<CommonResponseType  & { data: any }, CommonBody>({
    query: (body) => ({
      url: END_POINTS.teachingDetails,
      method: "POST",
      body,
    }),
  }),
  
  
    addTutorInfo: builder.mutation<CommonResponseType  & { data: TutorInfo }, CommonBody>({
      query: (body) => ({
        url: END_POINTS.addTutor,
        method: "POST",
        body,
      }),
    }),
    deleteTutorById:  builder.query<CommonResponseType & { data: any }, { userId: any}>({
    query: ({ userId }) => ({
      url: `${END_POINTS.deleteTutor}/${userId}`,
      method: "DELETE",
    }),
  }),
  TutorCsv: builder.query<CommonResponseType & { data: any }, {}>
  ({
    query: ({  }) => ({
      url: `${END_POINTS.parentExport}`,
      method: "GET"
    })
  }),

  TutorActivity: builder.query<CommonResponseType & { data: any }, { userId: any, bookingType: any }>
  ({
    query: ({ userId, bookingType }) => ({
      url: `${END_POINTS.activityHistory}/${userId}/?bookingType=${bookingType}`,
      method: "GET"
    })
  }),
 
    updateWithdrawStatus: builder.mutation<CommonResponseType, withdrawStatusParams>({
      query: ({ userId, encryptedBody, page }) => {
        let url = `${END_POINTS.withdrawStatus}/${userId}`;
        if (page) {
          url += `?page=${page}`;
        }
        return {
          url,
          method: 'PUT',
          body: encryptedBody,
        };
      },
    }),
 
    PutupdateWithdrawStatus: builder.mutation<CommonResponseType, withdrawStatusBody>({
      query: ({ encryptedBody }) => ({
        url: `${END_POINTS.withdrawStatus}`,
        method: 'PUT',
        body: encryptedBody,
      }),
    }),
    
    PutTutoraStaus: builder.mutation<CommonResponseType, withdrawStatusBody>({
      query: ({ encryptedBody }) => ({
        url: `${END_POINTS.updateTutor}`,
        method: 'PUT',
        body: encryptedBody,
      }),
    }),
    GetnextTutorById: builder.query<CommonResponseType & { data: any }, { userId: any}>
    ({
      query: ({ userId }) => ({
        url: `getTutor?${END_POINTS.currTutor}=${userId}`,
        
        method: "GET"
      })
    }),
  
    
  getTutorEarningById: builder.query<CommonResponseType & { data: any }, { userId: any}>
  ({
    query: ({ userId }) => ({
      url: `${END_POINTS.tutorEarning}/${userId}`,
      
      method: "GET"
    })
  }),
  }),
  
  
});

export const {
  usePutTeachingDetailsMutation,
    useLazyGetutorByIdQuery,
    useLazyGetAllTutorListingQuery,
  useAddTutorInfoMutation,
  usePutTutorProfileMutation,
  useLazyDeleteTutorByIdQuery,
  // useLazyPutupdateTutotQuery,
  usePostTutorTeachingDetailsMutation,
  useLazyTutorCsvQuery,
  useLazyGetTeachingDetailsByIdQuery,
  useLazyGetTutorDetailsQuery,
  useLazyGetReviewByIdQuery,
  useLazyTutorActivityQuery,
  useLazyGetTutorEarningByIdQuery,
  useUpdateWithdrawStatusMutation,
  useLazyGetAllPaymentRequestQuery,
  usePutupdateWithdrawStatusMutation,
  useLazyGetApprovedPaymentRequestQuery,
  useLazyGetRejectedPaymentRequestQuery,
  usePutTutoraStausMutation,
  usePutBulkTutorProfileMutation,
  usePutUpdateProfileTutorMutation,
  useLazyGetnextTutorByIdQuery
} = authApi;