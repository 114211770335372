import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import QuizIcon from "@mui/icons-material/Quiz";
import Person3Icon from "@mui/icons-material/Person3";
import NoteIcon from "@mui/icons-material/Note";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { PropsWithoutRef } from "react";
import { SIDEBAR_WIDTH } from "../helpers/constants/SideBarWidth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Settings from "@mui/icons-material/Settings";
import { useState, useRef, useEffect } from "react";
interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRef = useRef<HTMLDivElement | null>(null);
  const [isSelected, setIsSelected] = useState("");

  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure
          className="logo_cntnr"
          style={{
            backgroundColor: "white",
            marginBottom: 10,
            padding: 10,
            borderRadius: "20px",
          }}
          onClick={() => navigate("/dashboard")}
        >
          <img src="/static/images/app2.png" alt="" />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Dashboard" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manageUsers" ||
            location.pathname === "/manageusers" ||
            location.pathname.startsWith("/manageusers/userdetails/") ||
            location.pathname.startsWith("/manageusers/usersform/")
          }
          onClick={() => navigate("/manageusers")}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Users" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-tutors" ||
            location.pathname.startsWith("/manage-tutors/details") ||
            location.pathname.startsWith("/manage-tutors/edit") ||
            location.pathname.startsWith("/manage-tutor/add") ||
            location?.pathname.startsWith(
              "/manage-tutor/details/NextTutorDetail"
            )
          }
          onClick={() => navigate("/manage-tutors")}
        >
          <ListItemIcon>
            <Person3Icon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Tutors" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-bookings" ||
            location.pathname.startsWith("/manage-bookings/details")
          }
          onClick={() => navigate("/manage-bookings")}
        >
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Bookings" />
        </ListItemButton>
        {/* payment request */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/paymentRequest" ||
            location.pathname.startsWith("/paymentRequestDetails")
          }
          onClick={() => navigate("/paymentRequest")}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Payment Request" />
        </ListItemButton>
        {/* refund request */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/refundRequest" ||
            location.pathname.startsWith("/refundRequestDetails")
          }
          onClick={() => navigate("/refundRequest")}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Refund" />
        </ListItemButton>
        {/* refund request */}
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/payment" ||
            location.pathname.startsWith("/paymentDetails")
          }
          onClick={() => navigate("/payment")}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Payment" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/customer-support"}
          onClick={() => navigate("/customer-support")}
        >
          <ListItemIcon>
            <HelpCenterIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Customer Support" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-reviews" ||
            location.pathname.startsWith("/manage-reviews/details")
          }
          onClick={() => navigate("/manage-reviews")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-reviews") ? selectedRef : null}
          >
            <ThumbUpIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Reviews" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-notifications" ||
            location.pathname === "/recieved-notifications" ||
            location.pathname === "/manage-notifications/add"
          }
          onClick={() => navigate("/manage-notifications")}
        >
          <ListItemIcon
            ref={
              isSelected?.includes("/manage-notifications") ? selectedRef : null
            }
          >
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Notifications" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/analytics"}
          onClick={() => navigate("/analytics")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/analytics") ? selectedRef : null}
          >
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Reports and Analytics"
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/cms"}
          onClick={() => navigate("/cms")}
        >
          <ListItemIcon ref={isSelected?.includes("/cms") ? selectedRef : null}>
            <SummarizeTwoToneIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage CMS" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/faq"}
          onClick={() => navigate("/faq")}
        >
          <ListItemIcon ref={isSelected?.includes("/faq") ? selectedRef : null}>
            <QuizIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary=" Manage FAQs" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-settings"}
          onClick={() => navigate("/manage-settings")}
        >
          <ListItemIcon
            ref={isSelected?.includes("/manage-settings") ? selectedRef : null}
          >
            <Settings />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Settings" />
        </ListItemButton>
      </List>
    </>
  );

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    setIsSelected(location.pathname);
  }, [location.pathname, isSelected]);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedRef]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
