import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import MainContainer from "../../../layout/MainContainer";
import { DocumentViewer } from "../../../components";
import {
  Challenges,
  Details,
  Earning,
  Journeys,
  PostsAndStories,
  TutorRating,
} from "../../../features";
import { useLazyGetTeachingDetailsByIdQuery, useLazyGetTutorDetailsQuery, useLazyGetnextTutorByIdQuery, useLazyGetutorByIdQuery } from "../../../services/tutor";
import Loader from "../../../helpers/Loader";
import { TutorDetails } from "../../../types/General";
import { useLazyGetDocucmentsDetailsQuery } from "../../../services/documentation";
import moment from "moment";
import { errorToast } from "../../../helpers";
 
import ActivityHistoryTutor from "../../../features/tutor/ActivityHistory";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NextTutorDetail = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [modalType, setModalType] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const [ByIdDetails, setByIdDetails] = useState<any>();
  const[nextTutordata,setNextTutordata]=useState<any>([]);
  const[getTutorFetchById]=useLazyGetutorByIdQuery()
  const [data,setData]=useState<any>([])
  console.log("3iji", ByIdDetails);
  console.log("ByIdDetails", ByIdDetails);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [getTutorById, { isLoading }] = useLazyGetTutorDetailsQuery();
  const[nextTutor,setNextTutor]=useLazyGetnextTutorByIdQuery();
  const { _id } = useParams();
  const [getTeachingDetails]=useLazyGetTeachingDetailsByIdQuery()
  const [getDocs]=useLazyGetDocucmentsDetailsQuery()
  // const [getDetials]
  const fetchDataById = async (_id: any) => {
    try {
      const response = await getTutorById({ id: _id }).unwrap();
      console.log("9090", response);
      if (response.statusCode === 200) {
        setByIdDetails(response?.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const nextById = async (_id: any) => {
    try {
      const response = await nextTutor({ userId: _id }).unwrap();
      console.log("Fetched next tutor details:", response);
      if (response.statusCode === 200) {
        setNextTutordata(response?.data);
        // Navigate to the next tutor's profile page using `navigate`
        // navigate(`/next-tutor-detail/${response?.data?._id}`);
        if(_id!==undefined)
        navigate(`/manage-tutor/details/NextTutorDetail/${response?.data?._id}`)
    else{
       
        errorToast("No next tutor available")
        navigate("/manage-tutors");
    }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getTeaching=async(id:string)=>{
    try{
      const res=await getTeachingDetails({
        userId:id
      }).unwrap()
    }catch(error:any){
      // errorToast(error?.data?.message)
      errorToast("No next tutor available")
      navigate("/manage-tutors");
    }
  }

  const fetchDocs=async(id:string)=>{
    try{
      const res=await getDocs({
        userId:id
      }).unwrap()
      if(res?.statusCode===200){
        setData(res?.data)
      }
    }catch(error:any){
      // errorToast(error?.data?.message)
      // errorToast("No next tutor available")
      navigate("/manage-tutors");
    }
  }

  useEffect(() => {
    if (_id) {
      fetchDataById(_id);
      getTeaching(_id)
      fetchDocs(_id)
    
    }
  }, [_id]);


//   ooooooooooo48f97r43yf9y4r3fy4ir3

// check
  const val=data?.findIndex((item: { documentType: number; })=>item?.documentType===2);
  const idIndex=data?.findIndex((item: { documentType: number; })=>item?.documentType===4);
  const certificates=data?.findIndex((item: { documentType: number; })=>item?.documentType===3);
  console.log(val,"val")
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Tutor Profile</h1>
          { _id && (
  <Button
    className="btn btn_primary"
    onClick={() => nextById(_id)}
  >
    Next
  </Button>
)}

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-tutors");
            }}
          >
            Back
          </Button>
        
        </div>
        <Loader isLoad={isLoading} />
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img
                    src={
                      ByIdDetails?.image
                        ? ByIdDetails?.image
                        : `/static/images/user_placeholder.png`
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">{ByIdDetails?.name ||"-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Username</Typography>
                      <Typography component="p">
                        {ByIdDetails?.userName ||"-"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Las name</Typography>
                      <Typography component="p">Abdul Hussain</Typography>
                    </Box>
                  </Grid> */}
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {ByIdDetails?.email ||"-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">
                        + {ByIdDetails?.phoneNo ||"-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Date Of Birth</Typography>
                      <Typography component="p">
                        {" "}
                        {/* {ByIdDetails?.age ||"-"}{" "} */}
                        {ByIdDetails ? moment(ByIdDetails.age).format('DD-MM-YYYY') : "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Gender</Typography>

                      <Typography component="p">
                        {(() => {
                          switch (ByIdDetails?.gender) {
                            case "MALE":
                              return "Male";
                            case "FEMALE":
                              return "Female";

                            default:
                              return "-";
                          }
                        })()}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Total Teaching Experience
                      </Typography>
                      <Typography component="p">
                        {ByIdDetails?.teachingdetails[0]?.totalTeachingExperience||"-"}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Education</Typography>
                      <Typography component="p">
                        {(() => {
                          switch (
                            ByIdDetails?.teachingdetails[0]?.
                          ) {
                            case 1:
                              return "Bachelors";
                            case 2:
                              return "Masters";
                            case 3:
                              return "Ph.d";
                            default:
                              return "";
                          }
                        })()}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography component="h5">Teaching Option</Typography>
                      <Typography component="p">
                        {(() => {
                          switch (
                            ByIdDetails?.teachingdetails?.teachingOption
                          ) {
                            case 1:
                              return "Tutor Home";
                            case 2:
                              return "Own Home";
                            case null:
                              return "Teaching option not specified";
                            default:
                              return "";
                          }
                        })()}
                      </Typography>
                    </Box>
                  </Grid> */}

                 
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Short Bio</Typography>
                      <Typography component="p">
                        {ByIdDetails?.shortBio ||"-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5"> Address</Typography>
                      <Typography component="p">{ByIdDetails?.address?ByIdDetails?.address:"-"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">{ByIdDetails?.isBlocked===false?"Active":"Blocked"}</Typography>
                    </Box>
                  </Grid>

                  {idIndex !== -1 ? (
  <Grid item xs={12}>
    <Box>
      <Typography component="h5"  style={{padding:20}}>Id Proof</Typography>
      <Box className="docs_div">
        <Grid container spacing={2}>
          {data?.map((item: { documentType: number; frontImage: string | undefined; }, index: number) => (
            item.documentType === 4 ? (
              <Grid item xs={4} key={index}>
                <figure>
                  <img src={item.frontImage} alt="ID Proof" />
                </figure>
              </Grid>
            ) : null
          ))}
        </Grid>
      </Box>
    </Box>
  </Grid>
) : null}

                  
                  {/* <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Achievements</Typography>
                      <Box className="docs_div">
                        
                      {data?.map((item,index)=>(
                          <>
                      
                          {item?.documentType===2?(
                            <figure>
                            {/* <VisibilityIcon onClick={() => setOpen(true)} /> */}
  
                            {/* <img src={item?.frontImage} alt="/static/images/document.png" /> */}
                          {/* </figure> */}
                          {/* ):null} */}
                          
                          
                        {/* </> */}
                        {/* ))} */}
                        
                      {/* </Box> */}
                    {/* </Box> */}
                  {/* </Grid> */} 
                
                  {val !== -1 ? (
  <Grid item xs={12}>
    <Box>
      <Typography component="h5" style={{padding:20}}>Achievements</Typography>
      <Box className="docs_div">
        <Grid container spacing={2}>
          {data?.map((item: { documentType: number; frontImage: string | undefined; }, index: number) => (
            item.documentType === 2 ? (
              <Grid item xs={4} key={index}>
                <figure>
                  <img src={item.frontImage} alt="Achievement" />
                </figure>
              </Grid>
            ) : null
          ))}
        </Grid>
      </Box>
    </Box>
  </Grid>
) : null}


{certificates !== -1 ? (
  <Grid item xs={12}>
    <Box>
      <Typography component="h5"  style={{padding:20}}>Certifications</Typography>
      <Box className="docs_div">
        <Grid container spacing={2}>
          {data?.map((item: { documentType: number; frontImage: string | undefined; }, index: number) => (
            item.documentType === 3 ? (
              <Grid item xs={4} key={index}>
                <figure>
                  <img src={item.frontImage} alt="Certification" />
                </figure>
              </Grid>
            ) : null
          ))}
        </Grid>
      </Box>
    </Box>
  </Grid>
) : null}

                  
                  
                  {/* {state.userStatus === "PENDING" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            padding: "20px 0 0",
                            fontSize: "20px",
                            color: "#1d1d1d",
                            fontWeight: "700",
                          }}
                          component="h2"
                        >
                          Approval
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Accept
                        </Button>
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Reject
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null} */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                {/* <Tab label="Manual Payouts" {...a11yProps(0)} /> */}
                <Tab label="Earnings" {...a11yProps(0)} />
                <Tab label="Activity History" {...a11yProps(1)} />
                <Tab label="Ratings" {...a11yProps(2)} />
              </Tabs>
            </Box>
            {/* <CustomTabPanel value={value} index={0}>
              <TutorRating />
            </CustomTabPanel> */}
            <CustomTabPanel value={value} index={0}>
              <Earning />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ActivityHistoryTutor />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TutorRating />
            </CustomTabPanel>
          </Box>
        </Card>
        <DocumentViewer open={open} setOpen={setOpen} />
      </div>
    </MainContainer>
  );
};
export default NextTutorDetail;
