import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useLazyGetAllFaqQuery, useAddFaqMutation } from "../services/manageFaq";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { errorToast, successToast } from "../helpers";
import Loader from "../helpers/Loader";

const MainFaq = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [allfaq, setAllFaq] = useState<any>([]);
  const [addFsq] = useAddFaqMutation();
  const [getFaq,{isLoading}] = useLazyGetAllFaqQuery();

  const fetchgetFaq = async () => {
    try {
      const response = await getFaq({}).unwrap();
      if (response?.statusCode === 200) {
        setAllFaq(response?.data?.faq || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchhandleAddFaq = async () => {
    const body = {
      faq: [...allfaq, { question, answer }]
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await addFsq(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        await fetchgetFaq();
        setQuestion("");
        setAnswer("");
        successToast("FAQ added successfully")
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.message);
    }
  };

  

  const handleRemoveFaq = async (index: number) => {
    const updatedFaqs = [...allfaq];
    updatedFaqs.splice(index, 1);
    setAllFaq(updatedFaqs);
    try {
      let encryptedBody = generateEncryptedKeyBody({ faq: updatedFaqs }) as CommonBody;
      await addFsq(encryptedBody).unwrap();
      successToast("FAQ deleted successfully");
      await fetchgetFaq();
    } catch (error: any) {
      errorToast(error?.message);
      console.log(error);
    }
  };
  
  useEffect(() => {
    fetchgetFaq();
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <Loader isLoad={isLoading} />
        <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: "100%" }}>
            <Typography className="custom_label">Question</Typography>
            <TextField
              hiddenLabel
              type="text"
              name="name"
              variant="outlined"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Question"
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography className="custom_label">Answer</Typography>
            <TextField
              hiddenLabel
              type="text"
              name="name"
              variant="outlined"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              placeholder="Answer"
            />
          </FormControl>
          <Box className="faq_btn">
            <Button
              variant="contained"
              disabled={!question || !answer}
              className="btn btn_primary sm"
              onClick={fetchhandleAddFaq}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      {allfaq.map((item: any, index: number) => (
        <Grid key={index} item xs={12}>
          <Box className="faq_box">
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Question</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="name"
                variant="outlined"
                value={item.question}
                placeholder="Question"
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Answer</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="name"
                variant="outlined"
                value={item.answer}
                placeholder="Answer"
              />
            </FormControl>
            <Box className="faq_btn">
            <Button
  variant="contained"
  className="btn btn_primary sm"
  onClick={() => handleRemoveFaq(index)}
>
  Remove
</Button>

            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default MainFaq;
